import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Directive, HostListener, APP_INITIALIZER, NgModule } from '@angular/core';
import { __decorate } from 'tslib';
import { fromEvent } from 'rxjs';
import { defineCustomElements } from '@npm-davi/davi-coe-atoms-lib/loader';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/* eslint-disable */
/* tslint:disable */
const _c0 = ["*"];
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let DavAccordion = class DavAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davAccordionChange']);
  }
  static {
    this.ɵfac = function DavAccordion_Factory(t) {
      return new (t || DavAccordion)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavAccordion,
      selectors: [["dav-accordion"]],
      inputs: {
        multy: "multy"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavAccordion_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavAccordion = __decorate([ProxyCmp({
  inputs: ['multy']
})], DavAccordion);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAccordion, [{
    type: Component,
    args: [{
      selector: 'dav-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['multy']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavAccordionBody = class DavAccordionBody {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavAccordionBody_Factory(t) {
      return new (t || DavAccordionBody)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavAccordionBody,
      selectors: [["dav-accordion-body"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavAccordionBody_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavAccordionBody = __decorate([ProxyCmp({
  methods: ['setExpanded']
})], DavAccordionBody);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAccordionBody, [{
    type: Component,
    args: [{
      selector: 'dav-accordion-body',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavAccordionSection = class DavAccordionSection {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davAccordionChange']);
  }
  static {
    this.ɵfac = function DavAccordionSection_Factory(t) {
      return new (t || DavAccordionSection)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavAccordionSection,
      selectors: [["dav-accordion-section"]],
      inputs: {
        expanded: "expanded"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavAccordionSection_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavAccordionSection = __decorate([ProxyCmp({
  inputs: ['expanded']
})], DavAccordionSection);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAccordionSection, [{
    type: Component,
    args: [{
      selector: 'dav-accordion-section',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['expanded']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavAccordionTitle = class DavAccordionTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davAccordionToggleClick']);
  }
  static {
    this.ɵfac = function DavAccordionTitle_Factory(t) {
      return new (t || DavAccordionTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavAccordionTitle,
      selectors: [["dav-accordion-title"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavAccordionTitle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavAccordionTitle = __decorate([ProxyCmp({
  methods: ['setExpanded']
})], DavAccordionTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAccordionTitle, [{
    type: Component,
    args: [{
      selector: 'dav-accordion-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavAdvice = class DavAdvice {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavAdvice_Factory(t) {
      return new (t || DavAdvice)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavAdvice,
      selectors: [["dav-advice"]],
      inputs: {
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavAdvice_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavAdvice = __decorate([ProxyCmp({
  inputs: ['type']
})], DavAdvice);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAdvice, [{
    type: Component,
    args: [{
      selector: 'dav-advice',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavAnchorMenu = class DavAnchorMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davMenuClick']);
  }
  static {
    this.ɵfac = function DavAnchorMenu_Factory(t) {
      return new (t || DavAnchorMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavAnchorMenu,
      selectors: [["dav-anchor-menu"]],
      inputs: {
        items: "items"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavAnchorMenu_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavAnchorMenu = __decorate([ProxyCmp({
  inputs: ['items']
})], DavAnchorMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAnchorMenu, [{
    type: Component,
    args: [{
      selector: 'dav-anchor-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['items']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavBackButton = class DavBackButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavBackButton_Factory(t) {
      return new (t || DavBackButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavBackButton,
      selectors: [["dav-back-button"]],
      inputs: {
        davAriaLabel: "davAriaLabel"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavBackButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavBackButton = __decorate([ProxyCmp({
  inputs: ['davAriaLabel']
})], DavBackButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavBackButton, [{
    type: Component,
    args: [{
      selector: 'dav-back-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['davAriaLabel']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavBadge = class DavBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavBadge_Factory(t) {
      return new (t || DavBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavBadge,
      selectors: [["dav-badge"]],
      inputs: {
        color: "color"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavBadge_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavBadge = __decorate([ProxyCmp({
  inputs: ['color']
})], DavBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavBadge, [{
    type: Component,
    args: [{
      selector: 'dav-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavBottomSheet = class DavBottomSheet {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davCloseBottomSheet', 'davBackBottomSheet', 'davOpenBottomSheet']);
  }
  static {
    this.ɵfac = function DavBottomSheet_Factory(t) {
      return new (t || DavBottomSheet)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavBottomSheet,
      selectors: [["dav-bottom-sheet"]],
      inputs: {
        hasBackButton: "hasBackButton",
        switch: "switch",
        titleBottomSheet: "titleBottomSheet"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavBottomSheet_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavBottomSheet = __decorate([ProxyCmp({
  inputs: ['hasBackButton', 'switch', 'titleBottomSheet']
})], DavBottomSheet);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavBottomSheet, [{
    type: Component,
    args: [{
      selector: 'dav-bottom-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hasBackButton', 'switch', 'titleBottomSheet']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavButton = class DavButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavButton_Factory(t) {
      return new (t || DavButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavButton,
      selectors: [["dav-button"]],
      inputs: {
        ariaLabel: "ariaLabel",
        buttonSize: "buttonSize",
        buttonStyle: "buttonStyle",
        buttonTag: "buttonTag",
        buttonText: "buttonText",
        buttonType: "buttonType",
        disabled: "disabled",
        hasMinWidth: "hasMinWidth",
        linkHref: "linkHref",
        target: "target"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavButton = __decorate([ProxyCmp({
  inputs: ['ariaLabel', 'buttonSize', 'buttonStyle', 'buttonTag', 'buttonText', 'buttonType', 'disabled', 'hasMinWidth', 'linkHref', 'target']
})], DavButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavButton, [{
    type: Component,
    args: [{
      selector: 'dav-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['ariaLabel', 'buttonSize', 'buttonStyle', 'buttonTag', 'buttonText', 'buttonType', 'disabled', 'hasMinWidth', 'linkHref', 'target']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavCheckbox = class DavCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davChange']);
  }
  static {
    this.ɵfac = function DavCheckbox_Factory(t) {
      return new (t || DavCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavCheckbox,
      selectors: [["dav-checkbox"]],
      inputs: {
        checkboxId: "checkboxId",
        checked: "checked",
        disabled: "disabled",
        name: "name",
        undefined: "undefined",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavCheckbox_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavCheckbox = __decorate([ProxyCmp({
  inputs: ['checkboxId', 'checked', 'disabled', 'name', 'undefined', 'value']
})], DavCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavCheckbox, [{
    type: Component,
    args: [{
      selector: 'dav-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checkboxId', 'checked', 'disabled', 'name', 'undefined', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavChip = class DavChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavChip_Factory(t) {
      return new (t || DavChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavChip,
      selectors: [["dav-chip"]],
      inputs: {
        iconChip: "iconChip",
        isCloseable: "isCloseable",
        size: "size",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavChip_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavChip = __decorate([ProxyCmp({
  inputs: ['iconChip', 'isCloseable', 'size', 'type']
})], DavChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavChip, [{
    type: Component,
    args: [{
      selector: 'dav-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['iconChip', 'isCloseable', 'size', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavContentBox = class DavContentBox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavContentBox_Factory(t) {
      return new (t || DavContentBox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavContentBox,
      selectors: [["dav-content-box"]],
      inputs: {
        border: "border",
        color: "color",
        radius: "radius",
        radiusStyle: "radiusStyle",
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavContentBox_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavContentBox = __decorate([ProxyCmp({
  inputs: ['border', 'color', 'radius', 'radiusStyle', 'size']
})], DavContentBox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavContentBox, [{
    type: Component,
    args: [{
      selector: 'dav-content-box',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['border', 'color', 'radius', 'radiusStyle', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavDatePicker = class DavDatePicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davFocus', 'davBlur', 'davKeyDown', 'davInput']);
  }
  static {
    this.ɵfac = function DavDatePicker_Factory(t) {
      return new (t || DavDatePicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavDatePicker,
      selectors: [["dav-date-picker"]],
      inputs: {
        ariaHelpText: "ariaHelpText",
        ariadescribedIconby: "ariadescribedIconby",
        disabled: "disabled",
        editable: "editable",
        error: "error",
        format: "format",
        name: "name",
        placeholder: "placeholder",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavDatePicker_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavDatePicker = __decorate([ProxyCmp({
  inputs: ['ariaHelpText', 'ariadescribedIconby', 'disabled', 'editable', 'error', 'format', 'name', 'placeholder', 'value']
})], DavDatePicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavDatePicker, [{
    type: Component,
    args: [{
      selector: 'dav-date-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['ariaHelpText', 'ariadescribedIconby', 'disabled', 'editable', 'error', 'format', 'name', 'placeholder', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavDescriptionCard = class DavDescriptionCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavDescriptionCard_Factory(t) {
      return new (t || DavDescriptionCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavDescriptionCard,
      selectors: [["dav-description-card"]],
      inputs: {
        cardData: "cardData"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavDescriptionCard_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavDescriptionCard = __decorate([ProxyCmp({
  inputs: ['cardData']
})], DavDescriptionCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavDescriptionCard, [{
    type: Component,
    args: [{
      selector: 'dav-description-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cardData']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavDivider = class DavDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavDivider_Factory(t) {
      return new (t || DavDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavDivider,
      selectors: [["dav-divider"]],
      inputs: {
        color: "color",
        orientation: "orientation"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavDivider_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavDivider = __decorate([ProxyCmp({
  inputs: ['color', 'orientation']
})], DavDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavDivider, [{
    type: Component,
    args: [{
      selector: 'dav-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'orientation']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavDropdown = class DavDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davFocus', 'davBlur', 'davChange']);
  }
  static {
    this.ɵfac = function DavDropdown_Factory(t) {
      return new (t || DavDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavDropdown,
      selectors: [["dav-dropdown"]],
      inputs: {
        adviceText: "adviceText",
        adviceType: "adviceType",
        arialabel: "arialabel",
        disabled: "disabled",
        error: "error",
        label: "label",
        labelOptional: "labelOptional",
        name: "name",
        options: "options",
        placeholder: "placeholder",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavDropdown_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavDropdown = __decorate([ProxyCmp({
  inputs: ['adviceText', 'adviceType', 'arialabel', 'disabled', 'error', 'label', 'labelOptional', 'name', 'options', 'placeholder', 'value'],
  methods: ['changeFocus']
})], DavDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavDropdown, [{
    type: Component,
    args: [{
      selector: 'dav-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['adviceText', 'adviceType', 'arialabel', 'disabled', 'error', 'label', 'labelOptional', 'name', 'options', 'placeholder', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavHelper = class DavHelper {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavHelper_Factory(t) {
      return new (t || DavHelper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavHelper,
      selectors: [["dav-helper"]],
      inputs: {
        linesToShow: "linesToShow"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavHelper_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavHelper = __decorate([ProxyCmp({
  inputs: ['linesToShow']
})], DavHelper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavHelper, [{
    type: Component,
    args: [{
      selector: 'dav-helper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['linesToShow']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavIcon = class DavIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavIcon_Factory(t) {
      return new (t || DavIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavIcon,
      selectors: [["dav-icon"]],
      inputs: {
        backgroundColor: "backgroundColor",
        backgroundSize: "backgroundSize",
        color: "color",
        name: "name",
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavIcon_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavIcon = __decorate([ProxyCmp({
  inputs: ['backgroundColor', 'backgroundSize', 'color', 'name', 'size']
})], DavIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavIcon, [{
    type: Component,
    args: [{
      selector: 'dav-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['backgroundColor', 'backgroundSize', 'color', 'name', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavIconCard = class DavIconCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavIconCard_Factory(t) {
      return new (t || DavIconCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavIconCard,
      selectors: [["dav-icon-card"]],
      inputs: {
        cardData: "cardData"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavIconCard_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavIconCard = __decorate([ProxyCmp({
  inputs: ['cardData']
})], DavIconCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavIconCard, [{
    type: Component,
    args: [{
      selector: 'dav-icon-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cardData']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavImage = class DavImage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavImage_Factory(t) {
      return new (t || DavImage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavImage,
      selectors: [["dav-image"]],
      inputs: {
        alt: "alt",
        radius: "radius",
        src: "src"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavImage_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavImage = __decorate([ProxyCmp({
  inputs: ['alt', 'radius', 'src']
})], DavImage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavImage, [{
    type: Component,
    args: [{
      selector: 'dav-image',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alt', 'radius', 'src']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavInput = class DavInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavInput_Factory(t) {
      return new (t || DavInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavInput,
      selectors: [["dav-input"]],
      inputs: {
        inputConfig: "inputConfig"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavInput_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavInput = __decorate([ProxyCmp({
  inputs: ['inputConfig']
})], DavInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavInput, [{
    type: Component,
    args: [{
      selector: 'dav-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['inputConfig']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavLabel = class DavLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavLabel_Factory(t) {
      return new (t || DavLabel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavLabel,
      selectors: [["dav-label"]],
      inputs: {
        access: "access",
        disabled: "disabled",
        label: "label",
        optional: "optional"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavLabel_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavLabel = __decorate([ProxyCmp({
  inputs: ['access', 'disabled', 'label', 'optional']
})], DavLabel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavLabel, [{
    type: Component,
    args: [{
      selector: 'dav-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['access', 'disabled', 'label', 'optional']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavLink = class DavLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavLink_Factory(t) {
      return new (t || DavLink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavLink,
      selectors: [["dav-link"]],
      inputs: {
        ariaLabel: "ariaLabel",
        href: "href",
        mode: "mode",
        target: "target",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavLink_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavLink = __decorate([ProxyCmp({
  inputs: ['ariaLabel', 'href', 'mode', 'target', 'type']
})], DavLink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavLink, [{
    type: Component,
    args: [{
      selector: 'dav-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['ariaLabel', 'href', 'mode', 'target', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavList = class DavList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavList_Factory(t) {
      return new (t || DavList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavList,
      selectors: [["dav-list"]],
      inputs: {
        hex: "hex",
        listStyle: "listStyle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavList_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavList = __decorate([ProxyCmp({
  inputs: ['hex', 'listStyle']
})], DavList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavList, [{
    type: Component,
    args: [{
      selector: 'dav-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hex', 'listStyle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavListItem = class DavListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavListItem_Factory(t) {
      return new (t || DavListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavListItem,
      selectors: [["dav-list-item"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavListItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavListItem = __decorate([ProxyCmp({})], DavListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavListItem, [{
    type: Component,
    args: [{
      selector: 'dav-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavLoader = class DavLoader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davLoaderEvent']);
  }
  static {
    this.ɵfac = function DavLoader_Factory(t) {
      return new (t || DavLoader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavLoader,
      selectors: [["dav-loader"]],
      inputs: {
        loaderData: "loaderData"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavLoader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavLoader = __decorate([ProxyCmp({
  inputs: ['loaderData']
})], DavLoader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavLoader, [{
    type: Component,
    args: [{
      selector: 'dav-loader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['loaderData']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavMainLayout = class DavMainLayout {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavMainLayout_Factory(t) {
      return new (t || DavMainLayout)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavMainLayout,
      selectors: [["dav-main-layout"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavMainLayout_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavMainLayout = __decorate([ProxyCmp({})], DavMainLayout);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavMainLayout, [{
    type: Component,
    args: [{
      selector: 'dav-main-layout',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavModal = class DavModal {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davModalEvent']);
  }
  static {
    this.ɵfac = function DavModal_Factory(t) {
      return new (t || DavModal)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavModal,
      selectors: [["dav-modal"]],
      inputs: {
        modalData: "modalData"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavModal_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavModal = __decorate([ProxyCmp({
  inputs: ['modalData']
})], DavModal);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavModal, [{
    type: Component,
    args: [{
      selector: 'dav-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['modalData']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavMoreButton = class DavMoreButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavMoreButton_Factory(t) {
      return new (t || DavMoreButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavMoreButton,
      selectors: [["dav-more-button"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavMoreButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavMoreButton = __decorate([ProxyCmp({})], DavMoreButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavMoreButton, [{
    type: Component,
    args: [{
      selector: 'dav-more-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavNotificationBadge = class DavNotificationBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavNotificationBadge_Factory(t) {
      return new (t || DavNotificationBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavNotificationBadge,
      selectors: [["dav-notification-badge"]],
      inputs: {
        badgeSize: "badgeSize",
        notificationsNumber: "notificationsNumber"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavNotificationBadge_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavNotificationBadge = __decorate([ProxyCmp({
  inputs: ['badgeSize', 'notificationsNumber']
})], DavNotificationBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavNotificationBadge, [{
    type: Component,
    args: [{
      selector: 'dav-notification-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['badgeSize', 'notificationsNumber']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavOption = class DavOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['elementSelected']);
  }
  static {
    this.ɵfac = function DavOption_Factory(t) {
      return new (t || DavOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavOption,
      selectors: [["dav-option"]],
      inputs: {
        description: "description",
        icon: "icon",
        isLast: "isLast",
        label: "label",
        optionValue: "optionValue",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavOption_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavOption = __decorate([ProxyCmp({
  inputs: ['description', 'icon', 'isLast', 'label', 'optionValue', 'selected']
})], DavOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavOption, [{
    type: Component,
    args: [{
      selector: 'dav-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['description', 'icon', 'isLast', 'label', 'optionValue', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavPredictive = class DavPredictive {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davFocus', 'davBlur', 'davInput', 'davKeyDown']);
  }
  static {
    this.ɵfac = function DavPredictive_Factory(t) {
      return new (t || DavPredictive)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavPredictive,
      selectors: [["dav-predictive"]],
      inputs: {
        ariadescribedby: "ariadescribedby",
        arialabel: "arialabel",
        ariarequired: "ariarequired",
        error: "error",
        name: "name",
        options: "options",
        placeholder: "placeholder",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavPredictive_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavPredictive = __decorate([ProxyCmp({
  inputs: ['ariadescribedby', 'arialabel', 'ariarequired', 'error', 'name', 'options', 'placeholder', 'value']
})], DavPredictive);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavPredictive, [{
    type: Component,
    args: [{
      selector: 'dav-predictive',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['ariadescribedby', 'arialabel', 'ariarequired', 'error', 'name', 'options', 'placeholder', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavProgressBar = class DavProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavProgressBar_Factory(t) {
      return new (t || DavProgressBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavProgressBar,
      selectors: [["dav-progress-bar"]],
      inputs: {
        currentStep: "currentStep",
        totalSteps: "totalSteps",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavProgressBar_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavProgressBar = __decorate([ProxyCmp({
  inputs: ['currentStep', 'totalSteps', 'type']
})], DavProgressBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavProgressBar, [{
    type: Component,
    args: [{
      selector: 'dav-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['currentStep', 'totalSteps', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavRadio = class DavRadio {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davChange']);
  }
  static {
    this.ɵfac = function DavRadio_Factory(t) {
      return new (t || DavRadio)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavRadio,
      selectors: [["dav-radio"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        name: "name",
        radioId: "radioId",
        radioStyle: "radioStyle",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavRadio_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavRadio = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'name', 'radioId', 'radioStyle', 'value']
})], DavRadio);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavRadio, [{
    type: Component,
    args: [{
      selector: 'dav-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'name', 'radioId', 'radioStyle', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavResume = class DavResume {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davClick']);
  }
  static {
    this.ɵfac = function DavResume_Factory(t) {
      return new (t || DavResume)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavResume,
      selectors: [["dav-resume"]],
      inputs: {
        resumeProps: "resumeProps"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavResume_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavResume = __decorate([ProxyCmp({
  inputs: ['resumeProps']
})], DavResume);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavResume, [{
    type: Component,
    args: [{
      selector: 'dav-resume',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['resumeProps']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavSelectCard = class DavSelectCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavSelectCard_Factory(t) {
      return new (t || DavSelectCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavSelectCard,
      selectors: [["dav-select-card"]],
      inputs: {
        cardData: "cardData"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavSelectCard_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavSelectCard = __decorate([ProxyCmp({
  inputs: ['cardData']
})], DavSelectCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavSelectCard, [{
    type: Component,
    args: [{
      selector: 'dav-select-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cardData']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavSelectionControlsContainer = class DavSelectionControlsContainer {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavSelectionControlsContainer_Factory(t) {
      return new (t || DavSelectionControlsContainer)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavSelectionControlsContainer,
      selectors: [["dav-selection-controls-container"]],
      inputs: {
        disabled: "disabled",
        instruction: "instruction",
        optional: "optional"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavSelectionControlsContainer_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavSelectionControlsContainer = __decorate([ProxyCmp({
  inputs: ['disabled', 'instruction', 'optional']
})], DavSelectionControlsContainer);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavSelectionControlsContainer, [{
    type: Component,
    args: [{
      selector: 'dav-selection-controls-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'instruction', 'optional']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavSelectionControlsGroup = class DavSelectionControlsGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavSelectionControlsGroup_Factory(t) {
      return new (t || DavSelectionControlsGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavSelectionControlsGroup,
      selectors: [["dav-selection-controls-group"]],
      inputs: {
        disabled: "disabled",
        instruction: "instruction",
        optional: "optional",
        selectionArray: "selectionArray",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavSelectionControlsGroup_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavSelectionControlsGroup = __decorate([ProxyCmp({
  inputs: ['disabled', 'instruction', 'optional', 'selectionArray', 'type']
})], DavSelectionControlsGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavSelectionControlsGroup, [{
    type: Component,
    args: [{
      selector: 'dav-selection-controls-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'instruction', 'optional', 'selectionArray', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavSnackbar = class DavSnackbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavSnackbar_Factory(t) {
      return new (t || DavSnackbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavSnackbar,
      selectors: [["dav-snackbar"]],
      inputs: {
        displayMode: "displayMode",
        isCloseable: "isCloseable",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavSnackbar_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavSnackbar = __decorate([ProxyCmp({
  inputs: ['displayMode', 'isCloseable', 'type']
})], DavSnackbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavSnackbar, [{
    type: Component,
    args: [{
      selector: 'dav-snackbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['displayMode', 'isCloseable', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavStepCard = class DavStepCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavStepCard_Factory(t) {
      return new (t || DavStepCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavStepCard,
      selectors: [["dav-step-card"]],
      inputs: {
        bottomBoxColor: "bottomBoxColor",
        description: "description",
        number: "number",
        upperBoxColor: "upperBoxColor"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavStepCard_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavStepCard = __decorate([ProxyCmp({
  inputs: ['bottomBoxColor', 'description', 'number', 'upperBoxColor']
})], DavStepCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavStepCard, [{
    type: Component,
    args: [{
      selector: 'dav-step-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['bottomBoxColor', 'description', 'number', 'upperBoxColor']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavSwitch = class DavSwitch {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davChange']);
  }
  static {
    this.ɵfac = function DavSwitch_Factory(t) {
      return new (t || DavSwitch)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavSwitch,
      selectors: [["dav-switch"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        name: "name",
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavSwitch_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavSwitch = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'name', 'size']
})], DavSwitch);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavSwitch, [{
    type: Component,
    args: [{
      selector: 'dav-switch',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'name', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTab = class DavTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavTab_Factory(t) {
      return new (t || DavTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTab,
      selectors: [["dav-tab"]],
      inputs: {
        active: "active",
        disabled: "disabled",
        panel: "panel",
        tabHeader: "tabHeader",
        tabName: "tabName"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTab_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTab = __decorate([ProxyCmp({
  inputs: ['active', 'disabled', 'panel', 'tabHeader', 'tabName']
})], DavTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTab, [{
    type: Component,
    args: [{
      selector: 'dav-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'disabled', 'panel', 'tabHeader', 'tabName']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTabPanel = class DavTabPanel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavTabPanel_Factory(t) {
      return new (t || DavTabPanel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTabPanel,
      selectors: [["dav-tab-panel"]],
      inputs: {
        active: "active",
        name: "name"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTabPanel_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTabPanel = __decorate([ProxyCmp({
  inputs: ['active', 'name']
})], DavTabPanel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTabPanel, [{
    type: Component,
    args: [{
      selector: 'dav-tab-panel',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'name']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTabs = class DavTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davChange']);
  }
  static {
    this.ɵfac = function DavTabs_Factory(t) {
      return new (t || DavTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTabs,
      selectors: [["dav-tabs"]],
      inputs: {
        activeName: "activeName",
        darkness: "darkness"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTabs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTabs = __decorate([ProxyCmp({
  inputs: ['activeName', 'darkness'],
  methods: ['activateTab']
})], DavTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTabs, [{
    type: Component,
    args: [{
      selector: 'dav-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activeName', 'darkness']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavText = class DavText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavText_Factory(t) {
      return new (t || DavText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavText,
      selectors: [["dav-text"]],
      inputs: {
        color: "color",
        isCondensed: "isCondensed",
        isItalic: "isItalic",
        isTitle: "isTitle",
        size: "size",
        tag: "tag",
        textAlign: "textAlign",
        weight: "weight"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavText_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavText = __decorate([ProxyCmp({
  inputs: ['color', 'isCondensed', 'isItalic', 'isTitle', 'size', 'tag', 'textAlign', 'weight']
})], DavText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavText, [{
    type: Component,
    args: [{
      selector: 'dav-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'isCondensed', 'isItalic', 'isTitle', 'size', 'tag', 'textAlign', 'weight']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTextarea = class DavTextarea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davInput', 'davFocus', 'davBlur']);
  }
  static {
    this.ɵfac = function DavTextarea_Factory(t) {
      return new (t || DavTextarea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTextarea,
      selectors: [["dav-textarea"]],
      inputs: {
        autocomplete: "autocomplete",
        disabled: "disabled",
        error: "error",
        maxlength: "maxlength",
        minlength: "minlength",
        name: "name",
        placeholder: "placeholder",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTextarea_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTextarea = __decorate([ProxyCmp({
  inputs: ['autocomplete', 'disabled', 'error', 'maxlength', 'minlength', 'name', 'placeholder', 'value']
})], DavTextarea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTextarea, [{
    type: Component,
    args: [{
      selector: 'dav-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autocomplete', 'disabled', 'error', 'maxlength', 'minlength', 'name', 'placeholder', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTextfield = class DavTextfield {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['davFocus', 'davBlur', 'davInput', 'davClick', 'davKeyDown']);
  }
  static {
    this.ɵfac = function DavTextfield_Factory(t) {
      return new (t || DavTextfield)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTextfield,
      selectors: [["dav-textfield"]],
      inputs: {
        actionButton: "actionButton",
        adviceText: "adviceText",
        adviceType: "adviceType",
        ariadescribedIconby: "ariadescribedIconby",
        ariadescribedby: "ariadescribedby",
        arialabel: "arialabel",
        ariarequired: "ariarequired",
        autocomplete: "autocomplete",
        disabled: "disabled",
        editable: "editable",
        error: "error",
        label: "label",
        labelOptional: "labelOptional",
        maxlength: "maxlength",
        minlength: "minlength",
        name: "name",
        placeholder: "placeholder",
        trailingIcon: "trailingIcon",
        type: "type",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTextfield_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTextfield = __decorate([ProxyCmp({
  inputs: ['actionButton', 'adviceText', 'adviceType', 'ariadescribedIconby', 'ariadescribedby', 'arialabel', 'ariarequired', 'autocomplete', 'disabled', 'editable', 'error', 'label', 'labelOptional', 'maxlength', 'minlength', 'name', 'placeholder', 'trailingIcon', 'type', 'value']
})], DavTextfield);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTextfield, [{
    type: Component,
    args: [{
      selector: 'dav-textfield',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['actionButton', 'adviceText', 'adviceType', 'ariadescribedIconby', 'ariadescribedby', 'arialabel', 'ariarequired', 'autocomplete', 'disabled', 'editable', 'error', 'label', 'labelOptional', 'maxlength', 'minlength', 'name', 'placeholder', 'trailingIcon', 'type', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTooltip = class DavTooltip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavTooltip_Factory(t) {
      return new (t || DavTooltip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTooltip,
      selectors: [["dav-tooltip"]],
      inputs: {
        colorTooltip: "colorTooltip",
        default: "default",
        direction: "direction",
        textTooltip: "textTooltip"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTooltip_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTooltip = __decorate([ProxyCmp({
  inputs: ['colorTooltip', 'default', 'direction', 'textTooltip']
})], DavTooltip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTooltip, [{
    type: Component,
    args: [{
      selector: 'dav-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['colorTooltip', 'default', 'direction', 'textTooltip']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DavTracker = class DavTracker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DavTracker_Factory(t) {
      return new (t || DavTracker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DavTracker,
      selectors: [["dav-tracker"]],
      inputs: {
        currentStep: "currentStep",
        steps: "steps"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DavTracker_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DavTracker = __decorate([ProxyCmp({
  inputs: ['currentStep', 'steps']
})], DavTracker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavTracker, [{
    type: Component,
    args: [{
      selector: 'dav-tracker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['currentStep', 'steps']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
const DIRECTIVES = [DavAccordion, DavAccordionBody, DavAccordionSection, DavAccordionTitle, DavAdvice, DavAnchorMenu, DavBackButton, DavBadge, DavBottomSheet, DavButton, DavCheckbox, DavChip, DavContentBox, DavDatePicker, DavDescriptionCard, DavDivider, DavDropdown, DavHelper, DavIcon, DavIconCard, DavImage, DavInput, DavLabel, DavLink, DavList, DavListItem, DavLoader, DavMainLayout, DavModal, DavMoreButton, DavNotificationBadge, DavOption, DavPredictive, DavProgressBar, DavRadio, DavResume, DavSelectCard, DavSelectionControlsContainer, DavSelectionControlsGroup, DavSnackbar, DavStepCard, DavSwitch, DavTab, DavTabPanel, DavTabs, DavText, DavTextarea, DavTextfield, DavTooltip, DavTracker];
class ValueAccessor {
  constructor(el) {
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  writeValue(value) {
    this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
  }
  handleChangeEvent(value) {
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.el.nativeElement.disabled = isDisabled;
  }
  static {
    this.ɵfac = function ValueAccessor_Factory(t) {
      return new (t || ValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ValueAccessor,
      hostBindings: function ValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusout", function ValueAccessor_focusout_HostBindingHandler() {
            return ctx._handleBlurEvent();
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValueAccessor, [{
    type: Directive,
    args: [{}]
  }], () => [{
    type: i0.ElementRef
  }], {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class TextValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  static {
    this.ɵfac = function TextValueAccessor_Factory(t) {
      return new (t || TextValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TextValueAccessor,
      selectors: [["dav-textfield"], ["dav-textarea"], ["dav-date-picker"], ["dav-predictive"]],
      hostBindings: function TextValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("davInput", function TextValueAccessor_davInput_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.value);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'dav-textfield, dav-textarea, dav-date-picker, dav-predictive',
      host: {
        '(davInput)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class SelectValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  static {
    this.ɵfac = function SelectValueAccessor_Factory(t) {
      return new (t || SelectValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SelectValueAccessor,
      selectors: [["dav-radio"], ["dav-dropdown"]],
      hostBindings: function SelectValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("davChange", function SelectValueAccessor_davChange_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.value);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'dav-radio, dav-dropdown',
      host: {
        '(davChange)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class BooleanValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  writeValue(value) {
    this.el.nativeElement.checked = this.lastValue = value == null ? false : value;
  }
  static {
    this.ɵfac = function BooleanValueAccessor_Factory(t) {
      return new (t || BooleanValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BooleanValueAccessor,
      selectors: [["dav-switch"], ["dav-checkbox"]],
      hostBindings: function BooleanValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("davChange", function BooleanValueAccessor_davChange_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.checked);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BooleanValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'dav-switch, dav-checkbox',
      host: {
        '(davChange)': 'handleChangeEvent($event.target.checked)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class DavAtomsAngularModule {
  static {
    this.ɵfac = function DavAtomsAngularModule_Factory(t) {
      return new (t || DavAtomsAngularModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DavAtomsAngularModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: () => defineCustomElements,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DavAtomsAngularModule, [{
    type: NgModule,
    args: [{
      declarations: [...DIRECTIVES, TextValueAccessor, SelectValueAccessor, BooleanValueAccessor],
      exports: [...DIRECTIVES, TextValueAccessor, SelectValueAccessor, BooleanValueAccessor],
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: () => defineCustomElements,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * Public API Surface of dav-atoms-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BooleanValueAccessor, DIRECTIVES, DavAccordion, DavAccordionBody, DavAccordionSection, DavAccordionTitle, DavAdvice, DavAnchorMenu, DavAtomsAngularModule, DavBackButton, DavBadge, DavBottomSheet, DavButton, DavCheckbox, DavChip, DavContentBox, DavDatePicker, DavDescriptionCard, DavDivider, DavDropdown, DavHelper, DavIcon, DavIconCard, DavImage, DavInput, DavLabel, DavLink, DavList, DavListItem, DavLoader, DavMainLayout, DavModal, DavMoreButton, DavNotificationBadge, DavOption, DavPredictive, DavProgressBar, DavRadio, DavResume, DavSelectCard, DavSelectionControlsContainer, DavSelectionControlsGroup, DavSnackbar, DavStepCard, DavSwitch, DavTab, DavTabPanel, DavTabs, DavText, DavTextarea, DavTextfield, DavTooltip, DavTracker, SelectValueAccessor, TextValueAccessor };
