import { inject } from "@angular/core";
import mustache from "mustache";
import { PAGE } from "../constants/pages";
import { IDENTIFIERS_TAGGING } from "../constants/tagging";
import { ModalState } from "../model/state.model";
import { PostmessageRepository } from "../repository/postmessage.repository";
import { StateRepository } from "../repository/state.repository";
import { TaggingRepository } from "../repository/tagging.repository";

export class ModalUsecase {
  private readonly stateRepo = inject(StateRepository);
  private readonly tagginRepo = inject(TaggingRepository);
  private readonly postmessage = inject(PostmessageRepository);

  //data es lo que devolvio el back
  open(
    key: string,
    data?: any,
    nextPage?: (pageId: PAGE, data?: any) => any
  ): void {
    const modals = this.stateRepo.getModals();
    const title = this.stateRepo.getTitle();
    const modal = modals[key];
    const tagStructure = modals.TAGGING;
    const userId = this.stateRepo.getUserId();
    const entryPoint = this.stateRepo.getEntryPoint();

    modal.description = mustache.render(modal.description, data);
    data = {
      ...modal,
      ...data,
      originTitle: title,
      userId,
      entryPoint,
      titleChannel: this.defTitleChannel(),
    };

    this.tagginRepo.sendTag({
      key: IDENTIFIERS_TAGGING.PAGEVIEW,
      structure: tagStructure[IDENTIFIERS_TAGGING.PAGEVIEW],
      data,
    });

    const event = new CustomEvent("davOpenModal");
    document.dispatchEvent(event);
    this.stateRepo.$dataModal.emit(modal as ModalState);

    // click buttons listeners
    document.addEventListener("davModalEvent", (event: Event) => {
      this.clickButton(
        tagStructure,
        data,
        (event as CustomEvent).detail,
        nextPage
      );
      event.stopImmediatePropagation();
    });
  }

  private clickButton(
    tagging: any,
    data: any,
    button: string,
    nextPage?: (pageId: PAGE, data?: any) => any
  ) {
    // def button name
    if (button === "primaryButton") {
      data.buttonName = data.principalButton;
    } else {
      data.buttonName = data.secondaryButton;
    }

    // send tagging button
    this.tagginRepo.sendTag({
      key: IDENTIFIERS_TAGGING.CLICK,
      structure: tagging[IDENTIFIERS_TAGGING.CLICK],
      data,
    });

    // send postmessage button
    const modal: ModalState = { ...data };
    console.log(modal);

    let postmessage: any;
    let pageRedirect: PAGE | undefined;
    if (button === "primaryButton") {
      postmessage = modal.principalPostmessage;
      pageRedirect = modal.principalButtonRedirect;
    } else {
      postmessage = modal.secondaryPostmessage;
      pageRedirect = modal.secondaryButtonRedirect;
    }

    if (pageRedirect) {
      nextPage!(pageRedirect, data);
    } else {
      this.postmessage.sendPostmessage(postmessage, data);
    }
  }

  private defTitleChannel() {
    const currentPage = this.stateRepo.getCurrentPage();

    if (!currentPage) {
      return "Zona Llaves";
    }

    const currentDataPage = this.stateRepo.getPagesLoaded()[currentPage]!;
    return currentDataPage?.paramsPage?.titleChannel || "Zona Llaves";
  }
}
