import { ENDPOINT } from "../constants/pages";
import { Request } from "../model/request.model";
import { Response } from "../model/response.model";

export abstract class ApiRepository {
  public abstract sendRequest(
    enpoint: ENDPOINT,
    request: Request,
    token: string
  ): Promise<Response>;
}
