import { EventEmitter } from "@angular/core";
import { PAGE } from "../constants/pages";
import { ModalState, PageState, State } from "../model/state.model";

export abstract class StateRepository {
  public abstract $dataModal: EventEmitter<ModalState>;
  public abstract $showLoader: EventEmitter<boolean>;

  public abstract getState(): State;

  public abstract getChannel(): number;
  public abstract getChannelName(): string;
  public abstract setChannel(channel: number): void;

  public abstract getClientId(): string;
  public abstract setClientId(clientId: string): void;

  public abstract getTitle(): string;
  public abstract setTitle(title: string): void;

  public abstract getUrl(): string;
  public abstract setUrl(url: string): void;

  public abstract getZone(): string;
  public abstract setZone(zone: string): void;

  public abstract getUserId(): string;
  public abstract setUserId(userId: string): void;

  public abstract getEntryPoint(): string;
  public abstract setEntryPoint(entryPoint: string): void;

  public abstract getBearerToken(): string;
  public abstract setBearerToken(token: string): void;

  public abstract getCurrentPage(): PAGE;
  public abstract setCurrentPage(pageId: PAGE): void;

  public abstract getPagesLoaded(): { [pageId in PAGE]?: PageState };
  public abstract setPagesLoaded(steps: {
    [pageId in PAGE]?: PageState;
  }): void;

  public abstract getModals(): {
    TAGGING: Record<string, any>;
    [key: string]: ModalState | Record<string, any>;
  };
  public abstract setModals(modals: {
    TAGGING: Record<string, any>;
    [key: string]: ModalState | Record<string, any>;
  }): void;
}
