export enum PAGE {
  ONBOARDING = "page_onboarding",
  STAMP_ZONE = "page_stamp_zone",
  WITHOUTKEY = "page_withoutkey",
  REGISTER_ASSOCIATE = "page_register_associate",
  REGISTER_CHOOSE = "page_register_choose",
  REGISTER_CONFIRM = "page_register_confirm",
  REGISTER_WHAT_KEY = "page_register_what_key",
  REGISTER_RESULT_IN_PROCESS = "page_register_result_in_process",
  REGISTER_RESULT_SUCCESSFUL = "page_register_result_successful",
  REGISTER_RESULT_FAILED = "page_register_result_failed",
  CONSULT_KEYS = "page_consult_keys",
  CANCEL_KEY = "page_cancel_key",
  INITIAL = "page_initial",
  NOT_REDIRECT = "page_not_redirect",

  TRANSFER_ONBOARDING = "page_transfer_onboarding",
  TRANSFER_ACCOUNTS = "page_transfer_accounts",
  TRANSFER_INFORMATION = "page_transfer_information",
  TRANSFER_CONFIRMATION = "page_transfer_confirmation",
  TRANSFER_SUCCESSFUL = "page_transfer_successful",
  TRANSFER_MAKE = "page_transfer_make",
  TRANSFER_CHECK_STATUS = "page_transfer_check_status",
}

export enum ENDPOINT {
  PREVAL_ONBOARDING = "/v1/prevalidation-onboarding",
  SAVE_ONBOARDING = "/v1/save-onboarding",
  PREVAL_REGISTER = "/v1/prevalidation-register",

  CONSULT_KEYS = "/v1/consult-keys",
  CANCEL_KEY = "/v1/cancel-key",
  REGISTER_KEY = "/v1/register-key",

  PREVAL_TRANSFER = "/v1/prevalidation-transfer",
  PREVAL_KEY_TO_TRANSFER = "/v1/prevalidation-key-transfer",
  MAKE_TRANSFER = "/v1/make-transfer",
  CHECK_STATUS_TRANSFER = "/v1/check-status-transfer",

  SAVE_RESUME = "/v1/resume/save",
  GET_RESUME = "/v1/resume/get",
}
