import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { ENDPOINT, PAGE } from "../constants/pages";
import { Request } from "../model/request.model";
import { PlainResponse } from "../model/response.model";
import { ApiRepository } from "../repository/api.repository";
import { CryptoRepository } from "../repository/crypto.repository";
import { LoggerRepository } from "../repository/logger.repository";
import { StateRepository } from "../repository/state.repository";
import { ModalUsecase } from "./modal.usecase";
import { ParamsUsecase } from "./params.usecase";

export class ApiUsecase {
  private readonly loggerRepo = inject(LoggerRepository);
  private readonly stateRepo = inject(StateRepository);
  private readonly apiRepo = inject(ApiRepository);
  private readonly cryptoRepo = inject(CryptoRepository);
  private readonly modalUsecase = inject(ModalUsecase);
  private readonly paramsUsecase = inject(ParamsUsecase);
  private readonly router = inject(Router);

  public async appBoot(
    channel: number,
    entryPoint: string,
    sessionID: string,
    token: string
  ): Promise<void> {
    this.stateRepo.setChannel(channel);
    this.stateRepo.setClientId(sessionID);
    this.stateRepo.setEntryPoint(entryPoint);
    this.stateRepo.setBearerToken(token);
    this.loggerRepo.debug("Seteando data", this.stateRepo.getState());

    this.loggerRepo.debug("Cargando modales");
    
    try {
      await this.paramsUsecase.loadModals(); 
    } catch (error) {
      this.modalUsecase.open("ERROR");
      this.loggerRepo.error("Error en consumo", error);
    }
  }

  public async nextPage(
    redirectTo: PAGE,
    data?: any,
    noSavePrevious?: boolean
  ): Promise<any> {
    this.loggerRepo.debug("Data enviada a " + redirectTo, data);

    const previousPage = this.stateRepo.getCurrentPage();
    this.stateRepo.setCurrentPage(redirectTo);

    let stepsLoaded: any;
    try {
      stepsLoaded = await this.paramsUsecase.loadParamsCurrentPage();
    } catch (error) {
      this.modalUsecase.open("ERROR");
      this.loggerRepo.error("Error en consumo", error);
    }

    if (data) stepsLoaded[redirectTo]!.currentDataPage = data;
    if (!noSavePrevious) stepsLoaded[redirectTo]!.previousPage = previousPage;
    this.stateRepo.setPagesLoaded(stepsLoaded);

    this.router.navigate([redirectTo]);
  }

  public async callApi(endpoint: ENDPOINT, payload: any): Promise<any> {
    this.loggerRepo.debug("Payload enviado al endpoint:" + endpoint, payload);
    this.stateRepo.$showLoader.emit(true);

    const clientId = this.stateRepo.getClientId();
    const token = this.stateRepo.getBearerToken();

    this.loggerRepo.debug("Encriptando data");
    const encryptedPayload = await this.cryptoRepo.encrypt(payload);

    const request: Request = {
      clientId: clientId,
      payload: encryptedPayload,
    };

    let response: any;

    try {
      this.loggerRepo.debug("Enviando peticion", request);
      response = await this.apiRepo.sendRequest(endpoint, request, token);
    } catch (error) {
      this.stateRepo.$showLoader.emit(false);
      this.modalUsecase.open("ERROR");
      this.loggerRepo.error("Error en consumo", error);
    }

    const decryptedPayload: PlainResponse = await this.cryptoRepo.decrypt(
      response.payload
    );

    this.loggerRepo.debug("Respuesta del api", decryptedPayload);

    this.stateRepo.setUserId(decryptedPayload.data.userId);

    if (decryptedPayload.error) {
      this.stateRepo.$showLoader.emit(false);
      this.modalUsecase.open(
        decryptedPayload.error,
        decryptedPayload.data,
        (pageId: PAGE, data?: any) => this.nextPage(pageId, data, true)
      );
      this.loggerRepo.error("Error en consumo", decryptedPayload.error);
    } else {
      this.stateRepo.$showLoader.emit(false);
      return decryptedPayload.data;
    }
  }
}
